<!-- Localized -->
<template>
    <card-template :item="item" :selected="selected" :compact="compact" class="card-task"  :class="{
                'card-task--compact': !!compact,
            }">
        <template #content>
            <div class="property property--description">
                <div class="flex items-center">
                    <span class="font-semibold mr-4">{{ $t('components.dashboard.card.due') }}</span>
                    <div class="bg-gray-100 rounded-lg px-4 py-1 flex items-center mr-4">
                        <span class="font-semibold mr-2">{{ $t('general.date') }}</span>
                        <span class="text-gray-700">{{ FormatDate(item.target_time, "date") }}</span>
                    </div>
                    <div class="bg-gray-100 rounded-lg px-4 py-1 flex items-center mr-4">
                        <span class="font-semibold mr-2">{{ $t('general.time') }}</span>
                        <span class="text-gray-700">{{ FormatDate(item.target_time, "time") }}</span>
                    </div>
                    <div class="bg-gray-100 rounded-lg px-4 py-1 flex items-center ml-auto" v-if="item.assignee_name && item.assignee_type">
                        <span class="font-semibold mr-3">{{ $t('components.dashboard.card.assignee') }}</span>
                        <span class="text-gray-700 capitalize text-sm mr-2">{{ item.assignee_name }}</span>
                        <span class="text-gray-700 capitalize text-sm">({{ item.assignee_type }})</span>
                    </div>
                </div>
                <div class="bg-gray-100 rounded-lg px-2 py-0.5 flex mt-2">
                    <span class="font-semibold mr-2 flex-shrink-0">{{ $t('components.dashboard.card.description') }}</span>
                    <span class="text-gray-700" v-html="item.task_text"></span>
                </div>
                <!-- v-html="item.task_text" -->
                <!-- {{ item }} -->
            </div>
            <!-- <div class="">
                {{ FormatDate(item.target_time) }}
            </div> -->
        </template>
        <template #content-compact>
            <div class="font-semibold mr-3">{{ $t('components.dashboard.card.task') }}</div>
            <div class=" property property--date bg-gray-100 rounded-lg  py-0.5 flex ">
             <span class="font-semibold flex-shrink-0">{{ $t('components.dashboard.card.due') }}</span>
             <span class=" text-gray-700 mx-1"  v-if="item.target_time">
                {{ FormatDate(item.target_time,'date') }}, {{ FormatDate(item.target_time,'time') }}
            </span>
        </div>
        </template>
    </card-template>
</template>

<script>
import cardTemplate from "./card-base-template.vue";
import { DateTime } from "luxon";

export default {
    name: "card-task",
    components: {
        cardTemplate,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            required: false,
        },
        compact: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {};
    },
    methods: {
        FormatDate(date, type) {
            const obj = {
                date: "dd MMM yyyy",
                time: "hh:mm a",
            };
            return DateTime.fromJSDate(new Date(date)).toFormat(obj[type]);
        },
    },
};
</script>

<style lang="scss" scoped></style>
